.cursor-pointer{
    cursor: pointer;
}

// diet-custom-styled-select
.diet-custom-styled-select{
    .diet__menu{
        z-index: 999;
    }
}

// diet-plan-wrapper
.diet-plan-heading {
    background: #dedede;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    .heading-title {
        font-size: 18px;
    }

    .value {
        margin-left: 10px;
    }
}

.diet-plan-wrapper {
    margin-top: 20px;

    .diet-plan-header {
        .diet-plan-header {
            font-size: 16px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 10px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                height: 100%;
                width: 4px;
                background-color: #ff6c0b;
            }
        }

        .diet-plan-card-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 30px;

            @media(max-width: 991px) {
                margin-left: -5px;
                margin-right: -5px;
            }

            .diet-plan-card {
                width: calc((100% / 3) - 30px);
                box-shadow: 0px 3px 11px #858EA766;
                margin: 0 15px;
                border-radius: 10px;
                overflow: hidden;
                margin-bottom: 20px !important;

                @media(max-width: 1150px) {
                    width: calc((100% / 2) - 30px);
                }

                @media(max-width: 991px) {
                    margin: 0 5px;
                    width: calc((100% / 2) - 10px);
                }

                @media(max-width: 850px) {
                    margin: 0 5px;
                    width: calc((100% / 2) - 10px);
                }

                @media(max-width: 575px) {
                    margin: 0 5px;
                    width: calc((100% / 1) - 10px);
                }

                .food-hero-image {
                    height: 220px;
                    margin-bottom: 20px;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(rgba(255, 255, 255, 0.03), rgba(0, 0, 0, 0.6));
                    }

                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .food-name-calori {
                        position: absolute;
                        width: 100%;
                        padding: 10px;
                        bottom: 0;
                        left: 0;
                        margin-bottom: 0;
                        z-index: 1;

                        .food-name {
                            color: #ffffff;
                            font-size: 16px;
                            margin-bottom: 3px;
                        }

                        .food-calori {
                            color: #ffffff;
                            font-size: 12px;
                            font-style: italic;
                            margin-bottom: 0;
                            opacity: 0.8;
                        }
                    }
                }

                .food-content {
                    padding: 0 10px;

                    .food-aminities {
                        display: flex;
                        flex-wrap: wrap;

                        .food-tiles {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 0 10px;
                            // border: 1px solid #dedede;
                            margin-right: 10px;
                            border-radius: 2px;
                            margin-bottom: 10px;

                        }

                        .table {
                            border-radius: 10px;

                            .food-label {
                                color: #9d9c9c;
                                margin-bottom: 0;
                                margin-right: 5px;
                                font-size: 10px;
                            }

                            .food-value {
                                margin-bottom: 0;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            // .ant-ribbon-wrapper{

            // }
        }
    }
}


// custom badge
.custom-badge {
    right: -8px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 8px;
    height: 22px;
    padding: 0 8px;
    color: #fff;
    line-height: 22px;
    white-space: nowrap;
    background-color: #1890ff;
    border-radius: 2px;

    .ribbon-corner {
        position: absolute;
        top: 100%;
        width: 8px;
        height: 8px;
        color: currentColor;
        border: 4px solid;
        transform: scaleY(0.75);
        transform-origin: top;
        right: 0;
        border-color: rgb(255, 108, 11) transparent transparent rgb(255, 108, 11);

        &::after {
            position: absolute;
            top: -4px;
            left: -4px;
            width: inherit;
            height: inherit;
            color: rgba(0, 0, 0, 0.25);
            border: inherit;
            content: '';
        }
    }
}

// fitness badge
.fitness-badge {
    position: absolute;
    background: rgb(255, 108, 11);
    z-index: 1;
    right: 7px;
    border-bottom-right-radius: 0;

    .ribbon-corner {}
}

// fitness plan 
.fitness-plan-card {
    margin-bottom: 30px;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;



    .fitness-image-wrapper {
        height: 250px;
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(rgba(255, 255, 255, 0.03), rgba(0, 0, 0, 0.6));
            z-index: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .video-wrapper {
            position: absolute;
            top: 10px;
            left: 15px;
            z-index: 10;
            padding: 5px;
            border-radius: 8px;
            background-color: #dedede;
        }

        .excercise-name {
            font-size: 18px;
            color: #ffffff;
            margin-bottom: 0;
            padding-bottom: 4px;
            // border-bottom: 1px solid #dedede;
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 9;
            padding: 15px;
        }
    }

    .fitness-card-content {
        padding: 5px 15px;

        .steps-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0px;

            .aminities {
                color: #000000;
                padding-right: 5px;
                margin-bottom: 0;
                font-size: 12px;

                .label {
                    color: #898383;
                }

                .value {
                    color: #000000;
                }
            }
        }
    }

    .fitness-card-description {
        padding: 5px 15px;

        .description-heading {
            font-size: 14px;
            color: #898383;
        }

        .description-content {
            min-height: 160px;

            .desc-content {
                font-size: 12px;
                .read-me-wrapper{
                    color: rgb(255, 108, 11);
                    cursor: pointer;
                    font-weight: 600;
                    white-space: nowrap;
                }
            }

            .days-list {
                .days-heading {
                    font-size: 12px;
                    color: #898383;
                }

                .days-listing {
                    display: flex;
                    flex-wrap: wrap;

                    .day {
                        font-size: 10px;
                        background-color: #dedede;
                        border-radius: 4px;
                        margin-right: 5px;
                        display: flex;
                        padding: 0 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

// user list page
.user-card-wrapper{
    padding-top: 20px;
}


.table-wrapper{
    margin-top: 30px;
}

.dropzone {
    cursor: pointer;
    width: 100%;
    height: 20%;
    font-size: 57px;
    background-color: #eee;
}

.dropzone p {
    font-size: 25px;
}
