.notification-wrapper-page {
    .notification-sidebar {
        overflow: hidden;
        .notification-nav-item {
            .notification-link {
                color: #000000;
                font-weight: 600;
                padding: 15px;
                display: block;

                &.active {
                    color: #ffffff;
                    background-color: #ff5816;
                }
            }
        }
    }

    .notification-wrapper {
        .notification-messages {
            margin-bottom: 20px;
            padding: 5px 10px;
            background-color: #dedede;
            border-radius: 6px;

            .user-name {
                color: #ff5816;
            }

            .notification-msg-wrapper {
                display: flex;
                flex-wrap: wrap;


                .notification-item {
                    display: flex;
                    width: calc(100% - 200px);

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    .msg {
                        border-radius: 5px;
                        margin: 0;
                        text-align: left;
                        align-self: center;
                    }

                }
                .notification-date-time {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    width: 180px;
                    margin-left: 20px;

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-left: 0px;
                    }

                    .view-btn {
                        margin: 0;
                        align-self: flex-end;
                        font-style: italic;
                        font-weight: bold;
                        font-size: 10px;
                        cursor: pointer;
                        padding: 2px 10px;
                    }

                    .notification-time {
                        font-style: italic;
                        white-space: nowrap;
                        margin-top: 10px;
                    }

                }
            }
        }
    }
}