.text-custom-popup-wrapper{
    position: fixed;
    left:0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(18, 18, 18, 0.33);
    width: 100%;
    height: 100%;
    z-index: 99999;
    .text-custom-popup {
        background-color: #ffffff;
        border: 3px solid #333333;
        width: 100%;
        max-width: 600px;
        box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border-radius: 10px;
        transition: all ease-in-out 500ms;
    
        .heading-wrapper{
            padding: 15px;
            border-bottom: 1px solid #dedede;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            .text-heading {
                font-size: 20px;
                color: #333333;
                font-weight: 600;
            }
        }
    
        .full-description {
            padding: 20px 15px;
            font-size: 16px;
        }
    }
}